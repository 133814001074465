
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default defineComponent({
  computed: {
    ...mapGetters(['sidebarOpen', 'windowWidth', 'currentActiveRoute']),
    showOverlay(): boolean {
      if (this.sidebarOpen && this.windowWidth < 800) {
        return true;
      }
      return false;
    },
    sidebarStyle(): string {
      if (this.sidebarOpen && this.windowWidth < 800) {
        return `position: absolute;`;
      }

      return 'position: relative;';
    },
    contentStyle(): string | null {
      if (this.windowWidth < 800 && this.sidebarOpen) {
        return `width: ${this.windowWidth - 80}; margin-left: 8rem;`;
      }

      return null;
    },
  },

  methods: {
    ...mapActions(['toggle']),
  },
});
